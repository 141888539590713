<template>
  <div>
    <hr
      class="m-0"
      style="border: var(--border) !important"
    >
    <div
      v-if="hasOrganization && filteredOrganizations.length > 0"
      style="display: flex; align-items: center; justify-content: center;"
    >
      <v-expansion-panels
        id="org-selector"
        v-model="dropdownOpen"
        value="false"
      >
        <v-expansion-panel>
          <v-expansion-panel-header
            class="d-flex align-center p-3 pointer-event"
            :expand-icon="filteredOrganizations.length ? '$expand' : ''"
          >
            <template #default>
              <div
                class="text-center font-weight-bold"
                style="color: var(--datama-primary);"
              >
                {{ dropdownOpen !== undefined ? 'Hide change organization' : 'Change Organization' }}
              </div>
            </template>
            <template #actions>
              <i
                class="v-icon mdi mdi-chevron-down custom-chevron"
              />
            </template>
          </v-expansion-panel-header>

          <!-- Dropdown Content -->
          <v-expansion-panel-content
            id="org-list"
            class="p-0"
          >
            <v-list
              dense
              class="pt-0"
            >
              <v-list-item
                v-for="organization in filteredOrganizations"
                :key="organization.oid"
                class="org-item d-flex align-center"
                @click="switchOrganization(organization)"
              >
                <v-list-item-avatar>
                  <OrgAvatar
                    :editable="false"
                    :size="40"
                    :org="organization"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium">
                    {{ organization.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div
      v-else
      style="height: 20px"
    />
  </div>
</template>
<script>
import OrgAvatar from "@/components/Home/Org/Modals/OrgAvatar.vue";
import DataMaLogicAbstract from "@/components/Structure/Mixins/DataMaLogicAbstract.vue";
import OrgAbstract from "@/components/Home/Org/Mixins/OrgAbstract.vue";
import AuthAbstract from "@/components/Security/Mixins/AuthAbstract.vue";
import jwt from "jsonwebtoken";

export default {
  name: "OrgSelector",
  components: {
    OrgAvatar
  },
  mixins: [
    DataMaLogicAbstract,
    OrgAbstract,
    AuthAbstract
  ],
  props: {},
  data: () => {
    return {
      dropdownOpen: undefined
    }
  },
  computed: {
    filteredOrganizations() {
      return this.organizations.filter(org => org.oid !== this.organization.oid);
    }
  },
  methods: {
    to_organization() {
      this.$router.push({name: this.namespace + ':org-infos'})
    },
    fetchOrgs() {
      this.$store.dispatch("organization/getOrganizations")
    },
    switchOrganization(org) {
      this.$store.dispatch("organization/switchOrganization", {params: {oid: org.oid}, data: this.getAuthCookies()})
          .then(response => {
            this.storeAuthCookies(response.data.auth)
            this.getAuthCookies()
            this.decoded_token = jwt.decode(this.access_token)
            this.accessValue = {anonymous: false, token: this.access_token, decoded_token: this.decoded_token}
            this.registerToast(this.$toast.success(`Successfully switched to ${org.name}.`, {timeout: 3000}))
            this.$emit('close')
            this.refreshWorkbookList()
          })
          .catch(() => {
            this.registerToast(this.$toast.error(`Failed to switch org.`, {timeout: 3000}))
            this.$router.push({name: 'login'})
          })
    },
  }
}
</script>


<style lang="scss" scoped>
.org-selector-container {
  .v-expansion-panel-header {
    border-bottom: 1px solid #e0e0e0;
    padding: 12px 16px !important;
  }

  .v-expansion-panel-content {
    padding: 0 !important;
  }

  .v-list-item {
    &:hover {
      background-color: #f5f5f5;
    }
  }

  .v-icon {
    color: #1976d2;
    font-size: 24px;
  }
}

.custom-chevron {
  color: var(--color-secondary)
}

#org-selector {
  background: white;
  overflow: hidden;
  border-radius: 45px;
  margin: 20px;
  border: var(--border);
  width: 80%;
}

.org-item {
  color: var(--datama-primary) !important;
  border-top: var(--border)
}

.v-expansion-panel-header__icon > i {
  color: var(--datama-primary-light) !important;
}

#org-list::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>