var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:'flow ' +
    _vm.$store.state.prep.isSampleClass +
    ' ' +
    _vm.$store.state.asideClassExpand},[_c('div',{ref:"hubspot-target",attrs:{"id":"hubspot-target"}}),_c('ApplicationHeader',{ref:"header",on:{"reloadHeader":_vm.reloadHeader,"loadFlowData":_vm.loadFlowData,"resetFlow":_vm.resetSelectedFlow,"compute":_vm.compute,"refreshPage":_vm.refreshPage,"clearCache":_vm.clearCache}}),_c('router-view',{key:'content- ' + (_vm.reloadContent > 1 ? _vm.reloadContent : 0),ref:"view",staticClass:"child-view",class:[
      _vm.minimal === false ? 'core' : 'simple presentation',
      _vm.isCoreShaking === true ? 'screen-shake' : '',
    ],attrs:{"solution":_vm.currentRouteName.toLowerCase().split(':')[
        _vm.currentRouteName.toLowerCase().split(':').length - 1
      ],"configuration":_vm.solution_configuration,"minimal":_vm.minimal},on:{"loadFlowData":_vm.loadFlowData,"resetFlow":_vm.resetSelectedFlow,"save":_vm.save}}),(_vm.minimal !== false)?_c('SlidesPresentationArrows'):_vm._e(),_c('ExportPanel',{on:{"save":function($event){_vm.$refs.header != null ? _vm.$refs.header.save(false, true) : false}}}),_c('SolutionFooterPanel',{attrs:{"solution_configuration":_vm.solution_configuration},on:{"save":_vm.save}}),_c('SolutionTutorial',{attrs:{"solution":_vm.slug,"tutorial-scope":['application']}}),(_vm.isLiveFlow && _vm.liveFlowFunnel && !_vm.isSnowflake && !_vm.isAmazonRedshift)?_c('LiveFlowDialog',{attrs:{"selected-project":_vm.liveFlow.source.configuration.selectedProject},on:{"close":function($event){_vm.liveFlowFunnel = false},"load":_vm.loadSample,"compute":_vm.compute},model:{value:(_vm.liveFlow.source.configuration.table_connect),callback:function ($$v) {_vm.$set(_vm.liveFlow.source.configuration, "table_connect", $$v)},expression:"liveFlow.source.configuration.table_connect"}}):_vm._e(),(_vm.isLiveFlow && _vm.liveFlowFunnel && _vm.isSnowflake)?_c('LiveFlowDialogSnowflake',{attrs:{"warehouse":_vm.liveFlow.source.configuration.warehouse},on:{"close":function($event){_vm.liveFlowFunnel = false},"load":_vm.loadSample,"compute":_vm.compute},model:{value:(_vm.liveFlow.source.configuration.table_connect),callback:function ($$v) {_vm.$set(_vm.liveFlow.source.configuration, "table_connect", $$v)},expression:"liveFlow.source.configuration.table_connect"}}):_vm._e(),(_vm.isLiveFlow && _vm.liveFlowFunnel && _vm.isAmazonRedshift)?_c('LiveFlowDialogAmazonRedshift',{on:{"close":function($event){_vm.liveFlowFunnel = false},"load":_vm.loadSample,"compute":_vm.compute},model:{value:(_vm.liveFlow.source.configuration.table_connect),callback:function ($$v) {_vm.$set(_vm.liveFlow.source.configuration, "table_connect", $$v)},expression:"liveFlow.source.configuration.table_connect"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }