<template>
  <v-expansion-panels
    tile
    popout
    flat
    class="AiPannel"
  >
    <v-expansion-panel elevation="0">
      <v-expansion-panel-header disable-icon-rotate>
        <template #actions>
          <v-icon
            v-if="!isEnabling('open_ai')"
            class="AiHeaderIcon"
            :color="!isEnabled('open_ai') ? 'red' : 'teal'"
          >
            {{ !isEnabled('open_ai') ? 'mdi-close-circle' : 'mdi-check-circle' }}
          </v-icon>
          <v-progress-circular
            v-if="isEnabling('open_ai')"
            small
            class="AiHeaderIcon p-0 m-0"
            style="height:24px;width:24px;"
            color="primary"
            indeterminate
          />
        </template>
        <span class="AiHeaderTxt datama-title-text">
          OpenAI
          <DocumentationLink
            v-if="inputs.ai_enable_consent_box.description != null"
            :title="inputs.ai_enable_consent_box.description"
            :pro-tips="inputs.ai_enable_consent_box.proTips"
          />
        </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-btn
          v-if="!isEnabled('open_ai') || isEnabling('openai')"
          color="success"
          rounded
          block
          large
          @click="enableAi('open_ai')"
        >
          Enable ChatGPT
        </v-btn>
        <v-btn
          v-if="isEnabled('open_ai')"
          color="red"
          rounded
          block
          large
          @click="disableAi('open_ai')"
        >
          Disable ChatGPT
        </v-btn>
        <ConsentPopupLicense ref="confirm_license_open_ai" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import {inputs} from "@/config";
import DocumentationLink from "@/components/Core/Structure/Misc/DocumentationLink.vue";
import DataMaLogicAbstract from "@/components/Structure/Mixins/DataMaLogicAbstract.vue";
import ConsentPopupLicense from "@/components/Core/Solutions/Structure/Ai/Dialog/ConsentPopupLicense.vue";
// documentation
export default {
  components: {DocumentationLink, ConsentPopupLicense},
  mixins: [DataMaLogicAbstract],
  data() {
    return {
      enabled_ai: [""],
      enabling_ai: [""],
      license_dialog: false,
    }
  },
  computed: {
    inputs() {
      return inputs
    }
  },
    methods: {
    async enableAi(ai) {
      if (!(ai in this.enabled_ai)){
        this.enabling_ai.push(ai)
        this.license_dialog = true
        if (await this.$refs.confirm_license_open_ai.open('OpenAI',
            `By continuing, you must agree to Open Ai <a href="https://openai.com/policies/terms-of-use">Terms of use</a>, also, you agree to open ai <a href="https://openai.com/policies/privacy-policy">privacy policies</a>`, { color: 'red' })) {
          this.enabled_ai.push(ai)
          let k = this.enabling_ai.indexOf(ai)
          this.enabling_ai = this.enabling_ai.slice(k, 1)
        }
        else {
          let k = this.enabling_ai.indexOf(ai)
          this.enabling_ai = this.enabling_ai.slice(k, 1)
        }
      }
    },
    isEnabled(ai){
      return this.enabled_ai.includes(ai)
    },
    async consent(ai){

    },
    isEnabling(ai){
      return this.enabling_ai.includes(ai)
    },
    disableAi(ai) {
      if ((ai in this.enabled_ai)){
        let i = this.enabled_ai.indexOf(ai);
        this.enabled_ai = this.enabled_ai.slice(i,1)
      }
    }

  }


}
</script>
<style scoped lang="scss">
    .AiHeaderIcon {
      order: 0;
    }
    .AiPannel{
      border:1px solid lightgray;
      border-radius:30px;
    }
    .AiHeaderTxt {
      order: 1;
      margin-left:10px;
      border-left:1px solid lightgray;
      padding-left:10px;
      //font-family: "Blimone", sans-serif;
      //font-weight: bold;
      //color:var(--color-primary);
    }
</style>
