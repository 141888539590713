<template>
  <div>
    <v-btn
      color="blue"
      class="shadow-none transparent"
      style="color:white;"
      @click="openModal()"
    >
      <v-icon left>
        mdi-account-cog-outline
      </v-icon>
      Manage User
    </v-btn>
    <v-dialog
      v-model="dialog"
      max-width="1000"
    >
      <v-card
        class="datama-dialog d-flex flex-column"
        min-height="500"
      >
        <v-card-title>
          <div style="display: flex; align-items: center;">
            <span class="mr-1">Manage {{ selectedUser.username }}</span>
          </div>
          <v-icon
            class="transparent shadow-none"
            @click="closeModal"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text>
          <v-tabs
            v-model="activeTab"
          >
            <v-tabs-slider color="primary" />

            <v-tab
              v-for="tab in tabs"
              :key="tab"
            >
              {{ tab }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="activeTab">
            <v-tab-item>
              <v-card
                flat
                style="text-align: left"
              >
                <v-card-title>General</v-card-title>
                <v-card-text>
                  <div style="display: flex; justify-content: left; align-items: center; gap: 20px">
                    Enabled
                    <v-switch
                      v-model="is_enabled"
                      dense
                      hide-details="auto"
                      class="m-0 p-0"
                    />
                  </div>
                </v-card-text>
                <v-card-title>Actions</v-card-title>
                <v-card-text>
                  <ImpersonateUser
                    :selected-user="selectedUser"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-title>Roles</v-card-title>
                <v-card-text>
                  <RolesSelect
                    v-if="dialog===true"
                    :selected-user="selectedUser"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-title style="display: flex; align-items: center; justify-content: space-between">
                  Trials
                  <CreateTrialModal
                    :selected-user="selectedUser"
                    :trial-roles="trial_roles"
                    @update-trials-list="getTrials"
                  />
                </v-card-title>
                <v-card-text>
                  <v-row
                    v-if="trials.length > 0"
                    class="pa-0 ma-0 d-flex flex-wrap"
                    no-gutters
                    style="width: 100%; box-sizing: border-box"
                  >
                    <div
                      v-for="trial in trials"

                      :key="trial.id"
                      class="d-flex"
                      style="flex: 0 0 calc(50% - 8px); margin: 4px;"
                    >
                      <TrialCard
                        :trial="trial"
                        @update-trials-list="getTrials"
                      />
                    </div>
                  </v-row>
                  <span
                    v-else
                  >
                    No trials
                  </span>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-title>Subscriptions</v-card-title>
                <v-card-text>
                  Not available yet
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-spacer />
        <v-card-text
          class="p-3 footer-container"
        >
          <span
            v-for="(field, index) in fields"
            :key="field"
          >
            {{ field }}:
            <span :style="{ color: displayField(field) === 'null' ? 'var(--datama-warning)' : 'inherit' }">{{
              displayField(field)
            }}</span>
            <span v-if="index !== fields.length-1">,&nbsp;</span>
          </span>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import OrgAbstract from "@/components/Home/Org/Mixins/OrgAbstract.vue";
import AdminAbstract from "@/components/Home/Admin/Mixins/AdminAbstract.vue";
import {now} from "moment/moment";
import MemberCard from "@/components/Home/Admin/Cards/MemberCard.vue";
import TrialCard from "@/components/Home/Admin/Cards/TrialCard.vue";
import CreateTrialModal from "@/components/Home/Admin/Modals/CreateTrialModal.vue";
import RolesSelect from "@/components/Home/Admin/Selects/RolesSelect.vue";
import ImpersonateUser from "@/components/Home/Admin/Modals/ImpersonateUser.vue";
import DataMaLogicAbstract from "@/components/Structure/Mixins/DataMaLogicAbstract.vue";

export default {
  name: 'ManageUserModal',
  components: {ImpersonateUser, RolesSelect, CreateTrialModal, TrialCard},
  mixins: [
    OrgAbstract, AdminAbstract, DataMaLogicAbstract
  ],
  props: {
    selectedUser: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      trials: [],
      tabs: ['General', 'Roles', 'Trials', 'Subscriptions'],
      fields: ['username', 'email', 'first_name', 'last_name', 'created_at', 'updated_at'],
      activeTab: null,
      trial_roles: [],
    };
  },
  computed: {
    is_enabled: {
      get() {
        return !this.selectedUser.is_disabled
      },
      set(value) {
        this.$store.dispatch("admin/disableUser", {
          data: {
            uid: this.selectedUser.uid,
            is_disabled: !value
          }
        }).then((response) => {
          this.selectedUser.is_disabled = !value
          this.registerToast(this.$toast.success(`User "${this.selectedUser.username}" updated.`, {timeout: 10000}))
        }).catch((err) => {
          this.registerToast(this.$toast.error(err.toString(), {timeout: 10000}))
          console.error(err)
        })
      }
    },
  },
  watch: {},
  mounted() {
  },
  methods: {
    openModal() {
      this.dialog = true;
      this.getTrials()
    },
    getTrials() {
      this.loading = true
      return this.$store.dispatch("admin/getAllUserTrials", {
        params: {
          uid: this.selectedUser.uid
        },
      }).then((response) => {
        this.trials = response.data.trials
        this.trial_roles = this.trialRoles()
      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        this.loading = false
      })
    },
    displayField(field) {
      return this.selectedUser[field] ? this.selectedUser[field] : 'null'
    },
    trialRoles() {
      let trial_roles = []
      this.trials.forEach(trial => {
        trial.roles.forEach(role => {
          trial_roles.push(role)
        })
      })
      return trial_roles
    },
    impersonateUser() {
      this.loading = true
      this.$store.dispatch("admin/impersonateUser", {
        data: {
          uid: this.selectedUser?.uid
        },
      }).then((response) => {
        this.storeImpersonateUser(response.data.auth)
        this.loading = false
        this.dialog = false
      }).catch((err) => {
        this.loading = false
        this.$toast.error(err.toString())
        console.error(err)
      })
    },
    closeModal() {
      this.dialog = false;
      this.trials = []
    },
  },
};

</script>
<style scoped>
.footer-container {
  border-top: var(--border);
  background-color: rgba(var(--color-dark-thin-rgb), 0.1);
  display: flex;
  align-items: center;
  overflow-x: auto;
  white-space: nowrap;
  max-width: 100%;
  padding: 10px;
  position: relative;
}

.scroll-container::-webkit-scrollbar {
  height: 6px;
}

.scroll-container::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 3px;
}

.scroll-content {
  display: inline-block;
  white-space: nowrap;
}
</style>
