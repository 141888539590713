import { render, staticRenderFns } from "./OrgSelector.vue?vue&type=template&id=6459b197&scoped=true"
import script from "./OrgSelector.vue?vue&type=script&lang=js"
export * from "./OrgSelector.vue?vue&type=script&lang=js"
import style0 from "./OrgSelector.vue?vue&type=style&index=0&id=6459b197&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6459b197",
  null
  
)

export default component.exports